import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import plus from "../../assets/images/website/plus.png";

function FaqSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: {xs: '58px' , lg: "84px"} }}>
          <Typography
            sx={{
              color: "#212529",
              fontSize: 36,
              fontWeight: 700,
              fontFamily: '"Inter", sans-serif',
              lineHeight: "44px",
              textAlign: "center",
            }}
          >
            Sıkça Sorulan Sorular
          </Typography>
          <Typography
            sx={{
              color: "#212529",
              fontSize: {xs: 18 , lg :22},
              fontWeight: 400,
              fontFamily: '"Inter", sans-serif',
              lineHeight: "30px",
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            Bize sıkça sorulan soruların yanıtlarını burada bulabilirsiniz. 
          </Typography>
        </Box>
        <Box sx={{ margin: {xs: '39px 0px 89px' , lg: "50px 0px 165px"} }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              border: expanded === "panel1" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel1" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Nasıl çalışır?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                Blockchain hakkında temel bilgiler. 
                Blockchain teknolojisi sayesinde, her işlem kaydedilir ve geri dönülemez şekilde doğrulanır.
                Povego, kripto varlıkları blokzincir üzerinde güvenli ve şeffaf bir şekilde saklar. 
                Tokenizasyon, varlıkların dijital temsillerini oluşturarak, daha geniş kitlelere yatırım yapma imkânına verilen addır. 
                

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={{
              border: expanded === "panel2" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel2" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Neden Povego'yu tercih etmeliyiz?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                Hem yeni başlayanlar hem de profesyonellere hitap eden kullanıcı dostu arayüzümüz sayesinde hızlı ve kolay işlem yapabilirsiniz. 
                Geniş bir kripto para yelpazesi sunarak popüler tokenlardan yenilikçi projelere kadar birçok yatırım fırsatı sağlıyoruz. 
                Rekabetçi komisyon oranlarımız ve düzenli kampanyalarımızla maksimum kazanç elde etme şansı sunarken, hızlı işlem ve para çekme süreçlerimizle zamandan tasarruf etmenizi sağlıyoruz. 
                Türkçe 7/24 müşteri desteği ve Türkiye'ye özel ödeme yöntemleriyle kullanıcılarımızın her zaman yanında oluyoruz. Güvenilir, hızlı ve kullanıcı odaklı bir kripto yatırım deneyimi için doğru adrestesiniz. 

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={{
              border: expanded === "panel3" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel3" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Nasıl işlem yaparım?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                Povego’ya üye olduktan sonra banka hesabınızdan Türk Lirası transfer ederek kolayca alım-satım yapabilir, toplam varlıklarınızı ve hesap hareketlerinizi takip edebilirsiniz. 
                Povego’ya sadece kendi banka hesabınızdan para transfer edebilirsiniz. 3. kişilerin hesabından veya vergi kimlik numarası ile açılmış hesaplardan yapılan transferler kabul edilmemektedir. 
                EFT işlemi gerçekleştirecekseniz alıcı adını “Povego A.Ş.” olarak girmelisiniz. Havale yapacaksanız, IBAN numarasını girdikten sonra alıcı adı otomatik olarak ekrana gelecektir.
                Göndermek istediğiniz tutarı girdikten sonra işlem açıklamasını  boş bırakarak işleminizi tamamlayabilirsiniz. Bankanız işlem açıklamasını boş bırakmanıza izin vermiyorsa, ad soyad bilgilerinizi girebilirsiniz.
                Türk Lirası yatırma işleminizi, panelde Hesap Hareketleri bölümünden kolayca takip edebilirsiniz. Yatırdığınız tutar Povego hesabına ulaştıktan sonra kısa süre içerisinde hesabınıza aktarılacaktır. 

              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default FaqSection;
