import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function WhyPovego() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
            
              

              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 700,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 36, lg: 54 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '500px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
               Neden Povego?
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
                Avantajlarımız ve rakiplerimizden farkımız<br/><br/> 
                Povego'yu tercih etmeniz için birçok neden var. Öncelikle, fonlarınızı soğuk cüzdanlarda saklayan ve çok katmanlı güvenlik önlemleriyle koruyan güvenlik önceliğimiz, yatırımlarınızı güvende tutar.<br/><br/>
                Hem yeni başlayanlar hem de profesyonellere hitap eden kullanıcı dostu arayüzümüz sayesinde hızlı ve kolay işlem yapabilirsiniz. Geniş bir kripto para yelpazesi sunarak popüler tokenlardan yenilikçi projelere kadar birçok yatırım fırsatı sağlıyoruz.
                Rekabetçi komisyon oranlarımız ve düzenli kampanyalarımızla maksimum kazanç elde etme şansı sunarken, hızlı işlem ve para çekme süreçlerimizle zamandan tasarruf etmenizi sağlıyoruz.
                Türkçe 7/24 müşteri desteği ve Türkiye'ye özel ödeme yöntemleriyle kullanıcılarımızın her zaman yanında oluyoruz.
                Güvenilir, hızlı ve kullanıcı odaklı bir kripto yatırım deneyimi için doğru adrestesiniz.
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default WhyPovego;
  
