import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import HeroSection from "../components/WebpageComponent/HeroSection";
import TraditionalCom from "../components/WebpageComponent/TraditionalCom";
import InvestSection from "../components/WebpageComponent/InvestSection";
import OwnershipSection from "../components/WebpageComponent/OwnershipSection";
import FaqSection from "../components/WebpageComponent/FaqSection";
import Footer from "../components/WebpageComponent/Footer";
import sidebararrow from "../assets/images/sidebar_arrow.png"; // Sidebar arrow import
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"; // Import useSelector to access global state

function WebHomepage() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();
  const username = useSelector((state) => state.auth.username); // Access username from Redux store

  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: "#E63B1F" }}>
        <HeroSection />
      </Box>
      <TraditionalCom />
      <InvestSection />
      <OwnershipSection />
      <FaqSection />

      <Box
        className={`sidebar ${openSidebar ? "open" : ""}`}
        sx={{ backgroundColor: "#fff" }}
      >
        <Box sx={{ padding: "20px", textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "10px",
            }}
          >
            <svg
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setOpenSidebar(false)}
            >
              <path
                d="M1.9975 0L0 1.9975L6.48833 8.5L0 15.0025L1.9975 17L10.4975 8.5L1.9975 0Z"
                fill="#e63b1f"
              />
              <path
                d="M15.4986 17L17.4961 15.0025L11.0078 8.5L17.4961 1.9975L15.4986 -1.74627e-07L6.99859 8.5L15.4986 17Z"
                fill="#e63b1f"
              />
            </svg>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#e63b1f",
              cursor: "pointer",
            }}
          >
            What is Povego
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#e63b1f",
              cursor: "pointer",
              margin: "15px 0px",
            }}
          >
            How to trade
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#e63b1f",
              cursor: "pointer",
              margin: "15px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Resources
            <img
              src={sidebararrow}
              alt="sidebararrow"
              style={{ marginLeft: "10px" }}
            ></img>
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#e63b1f",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Company
            <img
              src={sidebararrow}
              alt="sidebararrow"
              style={{ marginLeft: "10px" }}
            ></img>
          </Typography>
          <Button
            sx={{
              fontWeight: 500,
              fontSize: 18,
              color: "#fff",
              backgroundColor: "#E63B1F",
              borderRadius: "48px",
              padding: "10px 38px",
              marginTop: "20px",
              textTransform: "none",
              fontFamily: '"Inter", sans-serif',
              "&::first-letter": {
                textTransform: "capitalize",
              },
              "&:hover": {
                background: "#fff",
              },
            }}
            onClick={() => {
              setOpenSidebar(false);
              navigate("/login");
            }}
          >
            {username ? username : "Login"}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default WebHomepage;
